import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "/src/components/Layout";
import * as styles from "./contact.module.css";
import { SEO } from "/src/components/SEO";
import map from "/src/images/map.png";
import Hours from "/src/components/Hours";

const ContactPage = () => {
  const { clinic } = useStaticQuery(graphql`
    query {
      clinic: graphCmsClinic {
        hours {
          closed
          dayOfWeek
          hours {
            close
            open
          }
        }
        address
        email
        phone
        name
        bookingUrl
      }
    }
  `);

  return (
    <Layout>
      <div className={styles.container}>
        <div>
          <h1>Hours & Location</h1>

          <div className={styles.grid}>
            <div>
              <p>
                <h2>Pacific Beach Clinic</h2>
                <a
                  href="https://www.google.com/maps/place/Personalized+Chiropractic/@32.8000875,-117.2583427,18z/data=!3m1!5s0x80dc018d858bb66d:0xbdd010b0ccb21575!4m5!3m4!1s0x80dc018d8576ce33:0x876b886aeac3a03e!8m2!3d32.800101!4d-117.2564008"
                  target="_blank"
                >
                  <span style={{ whiteSpace: "pre-wrap" }}>
                    {clinic.address}
                  </span>
                </a>
              </p>
              <p>
                We are conveniently located on Mission Blvd in the heart of
                Pacific Beach.
              </p>
            </div>
            <p>
              <h2>Parking</h2>
              Parking is available free of charge in the rear parking lot. Turn
              onto Missouri St. from Mission Blvd. and it is the first driveway
              on the right.
            </p>
            <p>
              <h2>Check-In</h2>
              When you arrive, you will enter our waiting room. Existing
              patients with an appointment can check in with a scanning the QR
              code at the front desk, or in person with a staff member.
            </p>
            <p>
              <h2>Appointments</h2>
              You may schedule appointments, cancel upcoming appointments, view
              your appointment history, and more using our{" "}
              <a href={clinic.bookingUrl} target="_blank">
                online booking system
              </a>
              .
            </p>
          </div>

          <div className={styles.gridSingleCol}>
            <a
              href="https://www.google.com/maps/place/Personalized+Chiropractic/@32.8000875,-117.2583427,18z/data=!3m1!5s0x80dc018d858bb66d:0xbdd010b0ccb21575!4m5!3m4!1s0x80dc018d8576ce33:0x876b886aeac3a03e!8m2!3d32.800101!4d-117.2564008"
              target="_blank"
              style={{ height: "fit-content" }}
              className={styles.mapMobile}
            >
              <img src={map} className={styles.map} alt="" />
            </a>

            <p>
              <Hours cols={4} />
            </p>

            <p>
              <h2>Call us</h2>
              <a
                href={`tel:${clinic.phone?.replaceAll(new RegExp(/\D/g), "")}`}
              >
                {clinic.phone}
              </a>
            </p>

            <p>
              <h2>Email us</h2>
              <a href={`mailto:${clinic.email}`}>{clinic.email}</a>
            </p>
          </div>
        </div>
        <a
          href="https://www.google.com/maps/place/Personalized+Chiropractic/@32.8000875,-117.2583427,18z/data=!3m1!5s0x80dc018d858bb66d:0xbdd010b0ccb21575!4m5!3m4!1s0x80dc018d8576ce33:0x876b886aeac3a03e!8m2!3d32.800101!4d-117.2564008"
          target="_blank"
          style={{ height: "fit-content" }}
          className={styles.mapDesktop}
        >
          <img src={map} className={styles.map} alt="" />
        </a>
      </div>
    </Layout>
  );
};

export default ContactPage;

export const Head = () => (
  <SEO title={`Contact Us — Personalized Chiropractic`} />
);
